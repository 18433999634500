<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.currencieseByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="currenciesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="currencies"
            :search="searchCurrencyString"
            :item-class="addCustomClass"
            class="elevation-1"
            multi-sort>
            <template v-for="header in currenciesHeaders" v-slot:[`item.${header.value}`]="{ item }">
              <td v-if="header.value !== 'default'" :key="header.value" @click="columnClick(item, header.value)">
                {{ item[header.value] }}
              </td>
            </template>

            <template v-slot:item.default="{ item }">
              <v-checkbox v-model="item.default" color="success" @click="makeCurrencyDefault(item)"></v-checkbox>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.currencies') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchCurrencyString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="currencyDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(currencyFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeCurrencyEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveCurrency)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field v-model="editedCurrencyItem.id" :label="$t('currenciesPage.id')">
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="currency">
                                  <v-text-field
                                    v-model="editedCurrencyItem.name"
                                    autofocus
                                    clearable
                                    counter="200"
                                    :label="$t('currenciesPage.currency')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|min:3|max:3" v-slot="{ errors }" name="code">
                                  <v-text-field
                                    v-model="editedCurrencyItem.code"
                                    clearable
                                    counter="3"
                                    :label="$t('currenciesPage.code')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:10" v-slot="{ errors }" name="symbol">
                                  <v-text-field
                                    v-model="editedCurrencyItem.symbol"
                                    clearable
                                    counter="10"
                                    :label="$t('currenciesPage.symbol')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="currencyDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedCurrencyItem.name }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeCurrencyDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('currenciesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="!enableCurrencySave" class="primary" text @click="deleteCurrencyItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td :key="notLastCurrency" @click="columnClick(item, 'actions')" class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editCurrencyItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="makeCurrencyActive(item)">
                          <v-icon v-if="item.active" class="mx-1" small v-bind="attrs"
                            >mdi-toggle-switch-outline</v-icon
                          >
                          <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                            >mdi-toggle-switch-off-outline</v-icon
                          >
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)" v-if="notLastCurrency">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { currenciesHeaders } from '@/mixins/data-table-headers';
import { defaultCurrency } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'CurrencyPage',
  components: { ButtonSubmit },
  data: () => ({
    searchCurrencyString: '',
    currencyDialog: false,
    currencyDialogDelete: false,
    currencies: [],
    currencyFormTitle: 'currenciesPage.newItem',
    currencyFormValid: true,
    editedCurrencyItem: {},
    enableCurrencySave: true,
    loading: false,
  }),
  created() {
    this.editedCurrencyItem = Object.assign({}, defaultCurrency);
    this.loadAllCurrencies();
  },
  computed: {
    currenciesHeaders() {
      return currenciesHeaders(i18n);
    },
    notLastCurrency() {
      return this.currencies.length > 1 ? true : false;
    },
  },
  methods: {
    async loadAllCurrencies() {
      await this.$store.dispatch('currencies/getAllCurrencies').then((res) => {
        this.currencies = res.data.map((e) => {
          e.active = parseInt(e.active);
          e.default = parseInt(e.default);
          return e;
        });
      });
    },

    editCurrencyItem(item) {
      this.editedCurrencyItem = this.currencies.indexOf(item);
      this.editedCurrencyItem = Object.assign({}, item);
      this.currencyFormTitle = item.name;
      this.currencyDialog = true;
    },

    deleteItem(item) {
      this.editedCurrencyItem = this.currencies.indexOf(item);
      this.editedCurrencyItem = Object.assign({}, item);
      this.currencyDialogDelete = true;
    },

    async saveCurrency() {
      this.enableCurrencySave = false;
      this.loading = true;
      let path = 'saveCurrency';

      if (this.editedCurrencyItem.id) {
        Object.assign(this.editedCurrencyItem, { _method: 'POST' });
        path = 'updateCurrency';
      }

      await this.$store
        .dispatch('currencies/' + path, this.editedCurrencyItem)
        .then(() => {
          this.loadAllCurrencies();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeCurrencyEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableCurrencySave = true;
          this.loading = false;
          this.currencyFormTitle = 'currenciesPage.newItem';
        });
    },

    async deleteCurrencyItemConfirm() {
      await this.$store
        .dispatch('currencies/deleteCurrency', this.editedCurrencyItem)
        .then((res) => {
          if (res.success) {
            this.loadAllCurrencies();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeCurrencyDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeCurrencyDelete();
        });
    },

    closeCurrencyEdit() {
      this.currencyDialog = false;
      this.$nextTick(() => {
        this.editedCurrencyItem = Object.assign({}, defaultCurrency);
      });
      this.$refs.form.reset();
      this.currencyFormTitle = 'currenciesPage.newItem';
    },

    closeCurrencyDelete() {
      this.currencyDialogDelete = false;
      this.$nextTick(() => {
        this.editedCurrencyItem = Object.assign({}, defaultCurrency);
      });
    },

    columnClick(item, column) {
      if (column !== 'default') {
        // Perform editItem action for other columns
        this.editCurrencyItem(item);
      }
    },

    async makeCurrencyActive(item) {
      await this.$store
        .dispatch('currencies/changeCurrencyActiveStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          item.active = !item.active;
        })
        .catch(() => {
          item.active = !item.active;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async makeCurrencyDefault(item) {
      await this.$store
        .dispatch('currencies/changeCurrencyDefaultStatus', item)
        .then(() => {
          this.loadAllCurrencies();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.primaryStatusChanged'), color: 'green' });
        })
        .catch(() => {
          item.default = !item.default;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  watch: {
    currencyDialog(val) {
      val || this.closeCurrencyEdit();
    },
  },
};
</script>
